import type * as yup from 'yup';
import type { FieldErrors, UseFormRegister } from 'react-hook-form';
import { formMessages } from '../../messages';
import type { FormValues, InputID } from '../types';

/**
 * Gather the basic props (that only depend on the id) for each input
 */
export const getFieldProps =
  (
    register: UseFormRegister<FormValues>,
    errors: FieldErrors<FormValues>,
    formatMessage: Function,
    schema: yup.ObjectSchema<
      | {
          firstname: string | undefined;
          lastname: string | undefined;
          email: string;
        }
      | {
          firstname: string | undefined;
          lastname: string | undefined;
          email: string;
          approval: boolean;
        },
      yup.AnyObject,
      | { firstname: undefined; lastname: undefined; email: undefined }
      | { firstname: undefined; lastname: undefined; email: undefined; approval: undefined },
      ''
    >,
  ) =>
  (inputId: InputID | 'approval', uniqueId: string) => ({
    ...register(inputId),
    id: `${inputId}-${uniqueId}`,
    helperText: errors[inputId]?.message,
    label:
      inputId in formMessages &&
      formMessages[inputId]?.label &&
      formatMessage(formMessages[inputId].label),
    error: !!errors[inputId],
    placeholder:
      inputId in formMessages &&
      formMessages[inputId]?.placeholder &&
      formMessages[inputId]?.placeholder.defaultMessage !== ''
        ? formatMessage(formMessages[inputId].placeholder)
        : undefined,
    required: !(schema.describe().fields[inputId] as yup.SchemaDescription).optional,
  });
