import { defineMessages } from 'react-intl';
import { CheckboxIDs, InputIDs } from './types';

/**
 * Messages for label, error and placeholder for each Input field
 */
export const formMessages = {
  [CheckboxIDs.APPROVAL]: undefined,
  [InputIDs.EMAIL]: defineMessages({
    label: {
      id: 'forms.type.email.label',
      defaultMessage: 'E-Mail Adresse',
    },
    error: {
      id: 'forms.type.email.error',
      defaultMessage: 'Bitte gib deine E-Mail an',
      valid: {
        id: 'forms.error.email',
        defaultMessage: 'Bitte gib eine gültige E-Mail-Adresse an',
      },
    },
    placeholder: {
      id: 'forms.type.email.placeholder',
      defaultMessage: 'name@anbieter.de',
    },
  }),
  [InputIDs.CUSTOMERID]: defineMessages({
    label: {
      id: 'forms.type.customernumber.label',
      defaultMessage: 'Kundennummer',
    },
    error: {
      id: 'forms.type.customernumber.error',
      defaultMessage: 'Bitte gib deine Kundennummer an!',
    },
    placeholder: {
      id: 'forms.type.customernumber.placeholder',
      defaultMessage: '',
    },
  }),

  [InputIDs.FIRSTNAME]: defineMessages({
    label: {
      id: 'forms.type.firstname.label',
      defaultMessage: 'Vorname',
    },
    error: {
      id: 'forms.type.firstname.error',
      defaultMessage: 'Bitte gib deinen Vornamen an!',
    },
    placeholder: {
      id: 'forms.type.firstname.placeholder',
      defaultMessage: '',
    },
  }),

  [InputIDs.LASTNAME]: defineMessages({
    label: {
      id: 'forms.type.lastname.label',
      defaultMessage: 'Nachname',
    },
    error: {
      id: 'forms.type.lastname.error',
      defaultMessage: 'Bitte gib deinen Nachnamen an!',
    },
    placeholder: {
      id: 'forms.type.lastname.placeholder',
      defaultMessage: '',
    },
  }),

  [InputIDs.STREET]: defineMessages({
    label: {
      id: 'forms.type.street.label',
      defaultMessage: 'Straße',
    },
    error: {
      id: 'forms.type.street.error',
      defaultMessage: 'Bitte gib deine Straße an!',
    },
    placeholder: {
      id: 'forms.type.street.placeholder',
      defaultMessage: '',
    },
  }),

  [InputIDs.HOUSENUMBER]: defineMessages({
    label: {
      id: 'forms.type.housenumber.label',
      defaultMessage: 'Hausnr.',
    },
    error: {
      id: 'forms.type.housenumber.error',
      defaultMessage: 'Bitte angeben!',
    },
    placeholder: {
      id: 'forms.type.housenumber.placeholder',
      defaultMessage: '',
    },
  }),

  [InputIDs.POSTALCODE]: defineMessages({
    label: {
      id: 'forms.type.postalcode.label',
      defaultMessage: 'Postleitzahl',
    },
    error: {
      id: 'forms.type.postalcode.error',
      defaultMessage: 'Bitte angeben!',
    },
    placeholder: {
      id: 'forms.type.postalcode.placeholder',
      defaultMessage: '',
    },
  }),

  [InputIDs.CITY]: defineMessages({
    label: {
      id: 'forms.type.city.label',
      defaultMessage: 'Ort',
    },
    error: {
      id: 'forms.type.city.error',
      defaultMessage: 'Bitte gib deinen Wohnort an!',
    },
    placeholder: {
      id: 'forms.type.city.placeholder',
      defaultMessage: '',
    },
  }),

  [InputIDs.PHONENUMBER]: defineMessages({
    label: {
      id: 'forms.type.phone.label',
      defaultMessage: 'Telefon',
    },
    error: {
      id: 'forms.type.phone.error',
      defaultMessage: '',
    },
    placeholder: {
      id: 'forms.type.phone.placeholder',
      defaultMessage: '',
    },
  }),

  [InputIDs.BIRTHDAY]: defineMessages({
    label: {
      id: 'forms.type.birthday.label',
      defaultMessage: 'Geburtsdatum',
    },
    error: {
      id: 'forms.type.birthday.error',
      defaultMessage: '',
    },
    placeholder: {
      id: 'forms.type.birthday.placeholder',
      defaultMessage: '',
    },
  }),

  [InputIDs.SALUTATION]: defineMessages({
    label: {
      id: 'forms.type.salutation.label',
      defaultMessage: 'Anrede',
    },
    error: {
      id: 'forms.type.salutation.error',
      defaultMessage: '',
    },
    placeholder: {
      id: 'forms.type.salutation.placeholder',
      defaultMessage: '',
    },
  }),

  [InputIDs.MESSAGE]: defineMessages({
    label: {
      id: 'forms.type.message.label',
      defaultMessage: 'Deine Nachricht',
    },
    error: {
      id: 'forms.type.message.error',
      defaultMessage: 'Bitte verfasse eine Nachricht!',
    },
    placeholder: {
      id: 'forms.type.message.placeholder',
      defaultMessage: 'Beschreibe bitte kurz, wie wir dir weiterhelfen können.',
    },
  }),

  [InputIDs.FREQUENCY]: defineMessages({
    label: {
      id: 'forms.type.frequency.label',
      defaultMessage: '',
    },
    error: {
      id: 'forms.type.frequency.error',
      defaultMessage: '',
    },
    placeholder: {
      id: 'forms.type.frequency.placeholder',
      defaultMessage: '',
    },
  }),

  [InputIDs.PAUSE]: defineMessages({
    label: {
      id: 'forms.type.pause.label',
      defaultMessage: 'Dauer',
    },
    error: {
      id: 'forms.type.pause.error',
      defaultMessage: '',
    },
    placeholder: {
      id: 'forms.type.pause.placeholder',
      defaultMessage: '',
    },
  }),

  [InputIDs.SUBJECT]: defineMessages({
    label: {
      id: 'forms.type.subject.label',
      defaultMessage: 'Betreff',
    },
    error: {
      id: 'forms.type.subject.error',
      defaultMessage: '',
    },
    placeholder: {
      id: 'forms.type.subject.placeholder',
      defaultMessage: 'Bitte wähle einen Betreff',
    },
  }),

  [CheckboxIDs.CANCELNL]: defineMessages({
    label: {
      id: 'forms.type.cancelnl.label',
      defaultMessage: 'Ich möchte den Newsletter abbestellen.',
    },
    error: {
      id: 'forms.type.cancelnl.error',
      defaultMessage: '',
    },
    placeholder: {
      id: 'forms.type.cancelnl.placeholder',
      defaultMessage: '',
    },
  }),

  [CheckboxIDs.CANCELADVERTISING]: defineMessages({
    label: {
      id: 'forms.type.canceladvertising.label',
      defaultMessage:
        'Ich widerspreche der Zusendung von Werbemitteln einschließlich der Kataloge.',
    },
    error: {
      id: 'forms.type.canceladvertising.error',
      defaultMessage: '',
    },
    placeholder: {
      id: 'forms.type.canceladvertising.placeholder',
      defaultMessage: '',
    },
  }),

  [CheckboxIDs.CANCELMARKETING]: defineMessages({
    label: {
      id: 'forms.type.cancelmarketing.label',
      defaultMessage:
        'Ich widerspreche der Nutzung, Verarbeitung und Weitergabe meiner Daten zu Marketingzwecken.',
    },
    error: {
      id: 'forms.type.cancelmarketing.error',
      defaultMessage: '',
    },
    placeholder: {
      id: 'forms.type.cancelmarketing.placeholder',
      defaultMessage: '',
    },
  }),

  [CheckboxIDs.TERMS]: defineMessages({
    label: {
      id: 'forms.type.terms.label',
      defaultMessage:
        'Ich akzeptiere die <avb>Allgemeinen Versicherungsbedingungen</avb> und nehme das Infoblatt gemäß FernFinG zur Kenntnis.',
    },
    linkIpid: {
      id: 'forms.type.terms.link.ipid',
      defaultMessage: '',
    },
    linkVag: {
      id: 'forms.type.terms.link.vag',
      defaultMessage: '',
    },
    linkAvb: {
      id: 'forms.type.terms.link.avb',
      defaultMessage: '',
    },
    linkVvinfo: {
      id: 'forms.type.terms.link.vvinfo',
      defaultMessage: '',
    },
    error: {
      id: 'forms.type.terms.error',
      defaultMessage: '',
    },
    placeholder: {
      id: 'forms.type.terms.placeholder',
      defaultMessage: '',
    },
  }),
};

export const specialErrorMessages = defineMessages({
  noSpecCharsNoNumbers: {
    id: 'forms.error.no.special.characters.no.numbers',
    defaultMessage: 'Sonderzeichen oder Zahlen sind nicht erlaubt',
  },
  noSpecChars: {
    id: 'forms.error.no.special.characters',
    defaultMessage: 'Sonderzeichen sind nicht erlaubt',
  },
  numbersOnly: {
    id: 'forms.error.numbersonly',
    defaultMessage: 'Nur Zahlen sind erlaubt',
  },
  email: {
    id: 'forms.error.email',
    defaultMessage: 'Bitte gib eine gültige E-Mail-Adresse an',
  },
  invalidDate: {
    id: 'forms.error.invaliddate',
    defaultMessage: 'Bitte gib ein gültiges Datum an',
  },
});

export const generalFormMessages = defineMessages({
  backToShop: {
    id: 'forms.back.to.shop',
    defaultMessage: 'Zurück zum Shop',
  },
  backToStorefront: {
    id: 'forms.back.to.storefront',
    defaultMessage: 'Zurück zur Startseite',
  },
  mandatory: {
    id: 'forms.mandatory',
    defaultMessage: 'Pflichtfelder sind mit einem Stern markiert',
  },
  errorApi: {
    id: 'forms.errorApi',
    defaultMessage: 'Es ist ein Fehler aufgetreten. Bitte versuche es später noch einmal.',
  },
  errorApiRecipientNotFound: {
    id: 'forms.errorApiRecipientNotFound',
    defaultMessage: 'Zu dieser E-Mail gibt es keine Newsletteranmeldung.',
  },
});

export const salutationSelectMessages = defineMessages({
  notSpecified: {
    id: 'forms.type.salutation.option.notSpecified',
    defaultMessage: 'divers',
  },
  female: {
    id: 'forms.type.salutation.option.female',
    defaultMessage: 'weiblich',
  },
  male: {
    id: 'forms.type.salutation.option.male',
    defaultMessage: 'männlich',
  },
});
