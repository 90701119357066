import { useIntl } from 'react-intl';
import { useDeviceType } from '@packages/shared/src/hooks/useDeviceType/useDeviceType';
import { Box } from '@packages/shared/src/components/Box/Box';
import { SuccessPage } from '../SuccessPage';
import { smallBasicContainer } from '../styles';
import { pagesMessages } from './helpers/messages';
import IconNlSubscribe3Successful from './animated/IconNlSubscribe3Successful';
import type { NewsletterSubscribePageProps } from '../types';

export const Page4KnownUser = ({
  headline,
  text,
  buttonLabel,
  buttonLabelMobile,
}: NewsletterSubscribePageProps) => {
  const intl = useIntl();
  const { isMobile } = useDeviceType();

  const customButtonLabel = isMobile ? buttonLabelMobile : buttonLabel;
  const finalButtonLabel = customButtonLabel || intl.formatMessage(pagesMessages.page4.button);

  return (
    <Box sx={smallBasicContainer}>
      <SuccessPage
        icon={<IconNlSubscribe3Successful sx={{ fontSize: '8rem', mb: 3 }} />}
        headline={headline || intl.formatMessage(pagesMessages.page4.headline)}
        description={text || intl.formatMessage(pagesMessages.page4.description)}
        button={{ text: finalButtonLabel, primary: true }}
      />
    </Box>
  );
};
