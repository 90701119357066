import { SubscribeNewsletterRecipientAfterDoiDocument } from '@packages/gql/generated/shopping/SubscribeNewsletterRecipientAfterDoiDocument';
import { useMutation } from 'urql';

/* GraphQl */ `
mutation SubscribeNewsletterRecipientAfterDoi($subscribeData: SubscribeWithTokenRequestDataInput) {
  subscribeNewsletterRecipientAfterDoi(subscribeData: $subscribeData) {
    ... on ErrorResponse {
      errors
    }
    ... on SubscribeResponseData {
      alreadySubscribed
      errors
      success
    }
  }
}`;

export const useAdditionalSubscribe = () =>
  useMutation(SubscribeNewsletterRecipientAfterDoiDocument);
