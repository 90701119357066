export type FormSubmitResult = {
  resultType:
    | 'CREATED_AND_SUBSCRIBED_SUCCESSFULLY'
    | 'NOT_CREATED_AND_SUBSCRIBED_SUCCESSFULLY'
    | 'ALREADY_SUBSCRIBED'
    | 'UNSUBSCRIBE_SUCCESSFUL'
    | 'PAUSE_SUCCESSFUL'
    | 'FREQUENCY_SUCCESSFUL'
    | 'MESSAGE_DELIVERED';
  successful: boolean;
};

export enum InputIDs {
  FIRSTNAME = 'firstname',
  LASTNAME = 'lastname',
  STREET = 'street',
  HOUSENUMBER = 'housenumber',
  CITY = 'city',
  POSTALCODE = 'postalCode',
  MESSAGE = 'message',
  EMAIL = 'email',
  FREQUENCY = 'frequency',
  PAUSE = 'pause',
  PHONENUMBER = 'phoneNumber',
  CUSTOMERID = 'customerid',
  BIRTHDAY = 'birthday',
  SUBJECT = 'subject',
  SALUTATION = 'salutation',
}

export enum CheckboxIDs {
  CANCELNL = 'cancelNL',
  CANCELADVERTISING = 'cancelAdvertising',
  CANCELMARKETING = 'cancelMarketing',
  TERMS = 'terms',
  APPROVAL = 'approval',
}

export enum Errors {
  REQUIRED = 'required',
  NOSPECCHARSNONUMBERS = 'noSpecCharsNoNumbers',
  NOSPECCHARS = 'noSpecChars',
  NUMBERSONLY = 'numbersonly',
  EMAIL = 'email',
  INVALIDDATE = 'invalidDate',
}

type ValueOf<T> = T[keyof T];
export type InputID = ValueOf<typeof InputIDs>;
export type CheckboxID = ValueOf<typeof CheckboxIDs>;
export type ErrorType = ValueOf<typeof Errors>;

export type FormValues = {
  [K in InputIDs]?: string;
} & {
  [K in CheckboxIDs]?: boolean;
};

export type FormRequiredFields = {
  [K in InputID]?: boolean;
} & {
  [K in CheckboxIDs]?: boolean;
};

export type ButtonLabel = {
  buttonLabel?: string;
  buttonLabelMobile?: string;
};

export type NewsletterSubscribePageProps = ButtonLabel & {
  headline?: string;
  text?: string;
  areaKey?: string;
};

export type SetErrorType = `root.${InputId}` | 'root';
export type InputId =
  | 'subject'
  | 'message'
  | 'firstname'
  | 'lastname'
  | 'street'
  | 'housenumber'
  | 'city'
  | 'postalCode'
  | 'email'
  | 'phoneNumber'
  | 'customerid';
export type ContactFormValues = {
  [InputIDs.MESSAGE]: string;
  [InputIDs.SUBJECT]: string;
  [InputIDs.FIRSTNAME]: string;
  [InputIDs.LASTNAME]: string;
  [InputIDs.STREET]?: string;
  [InputIDs.HOUSENUMBER]?: string;
  [InputIDs.CITY]?: string;
  [InputIDs.POSTALCODE]?: string;
  [InputIDs.EMAIL]: string;
  [InputIDs.PHONENUMBER]?: string;
  [InputIDs.CUSTOMERID]?: string;
};
